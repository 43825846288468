import './bootstrap';

import { createApp } from 'vue'
import CareerConditional from './vue/career-conditional.vue';
import Contact from './vue/contact.vue';
import SupaConditional from './vue/supa-conditional.vue';


const app = createApp()

app
.component('career-conditional', CareerConditional)
.component('contact', Contact)
.component('supa-conditional', SupaConditional)

app.mount('#app')

